import React from "react";
import "./SoftwareSkill.scss";
import { skillsSection } from "../../portfolio";

export default function SoftwareSkill() {
	return (
		<div>
			<div className="software-skills-main-div">
				<ul className="dev-icons">
					{skillsSection.softwareSkills.map((skills, i) => {
						return (
							<li
								key={i}
								className="software-skill-inline"
								name={skills.skillName}
							>
								{/* <i className={skills.iconClassname}></i> */}
								<iconify-icon icon={skills.iconClassname} width="36" height="36"></iconify-icon>
								<p>{skills.skillName}</p>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
}
